import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { ToastContainer } from 'react-toastify';

import NoMatchUrl from './NoMatchUrl';
import NotAllowedMessage from './NotAllowedMessage';
import Loading from './Loading';
import Error from './Error';
import Header from './Header';
import Menu from './Menu';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { Symfony } from './Symfony';
import packageInfo from '../../package.json';

const { version } = packageInfo;

const EntertainmentSection = React.lazy(() => import('../Sections/Entertainment'));
const Plugins = React.lazy(() => import('../Sections/Plugins'));
const MediaChannels = React.lazy(() => import('../Sections/MediaChannels'));
const PremiumAudio = React.lazy(() => import('../Sections/PremiumAudio'));
const UPshowNow = React.lazy(() => import('../Sections/UPshowNow'));
const GraphQL = React.lazy(() => import('../Sections/GraphQL'));
const Organizations = React.lazy(() => import('../Sections/Organizations'));
const Analytics = React.lazy(() => import('../Sections/Analytics'));
const LiveEvents = React.lazy(() => import('../Sections/LiveEvents'));
const UIVersioning = React.lazy(() => import('../Sections/UIVersioning'));
const ContentSection = React.lazy(() => import('../Sections/Content'));
const UIScript = React.lazy(() => import('../Sections/UIScript'));
const Users = React.lazy(() => import('../Sections/Users'));
const Devices = React.lazy(() => import('../Sections/Device'));
const FulfillmentOrder = React.lazy(() => import('../Sections/FulfillmentOrder'));
const SalesOrders = React.lazy(() => import('../Sections/SalesOrders'));
const Banners = React.lazy(() => import('../Sections/Banners'));

const Surveys = React.lazy(() => import('../Sections/Surveys'));
const SmartPaper = React.lazy(() => import('../Sections/SmartPaper'));
const BackOfHouse = React.lazy(() => import('../Sections/BackOfHouse'));
const TrackingCodes = React.lazy(() => import('../Sections/TrackingCodes'));

const Spotlight = React.lazy(() => import('../IndividualPages/Spotlight'));
const Content = React.lazy(() => import('../IndividualPages/Content'));
const Device = React.lazy(() => import('../IndividualPages/Device'));
const Note = React.lazy(() => import('../IndividualPages/Note'));
const TrackingCode = React.lazy(() => import('../IndividualPages/TrackingCode'));
const Notifications = React.lazy(() => import('../IndividualPages/Notifications'));

export const AppContext = React.createContext({ version });

const GET_ME = gql`
    query eager_whoAmi{
        version
        user: me {
            id
            avatar_url
            username
            email
            roles
            unreadNotifications
        }
    }
`;

function EmptySection () {
    return null;
}

function ErrorPage () {
    const query = new URLSearchParams(useLocation().search);
    return <Error error={{ message: query.get('error') ?? 'Error message tester' }}/>;
}

const Home = () => {
    const [expanded, setExpanded] = useState(false);
    
    const { loading, error, data } = useQuery(GET_ME, { pollInterval: 300_000 });
    const user = data && data.user;
    const roles = user?.roles;
    const graph_version = data?.version;

    function isAllowed (role) {
        if (!roles) return false;
        return roles.includes('ROLE_SUPER_BACKEND_ADMIN') || roles.includes(role);
    }

    useEffect(() => {
        if (user) {
            Sentry.setUser({ id: user.id });
        }
    }, [user]);

    if (error) {
        const message = error.networkError?.result?.errors[0].message ?? '';
        console.error(error)
        console.error(message)

        if (
                message.includes('Context creation failed: User can not be Backend')
                || message.includes('claim \'as_backend\' value \'undefined\' does not match expected value \'true\'')
                || message.includes('Context creation failed: WARNING!!!! User by userName')
        ) {
            window.oktaAuth.signOut();
            return null;
        }
    }

    return <AppContext.Provider value={{ user, graph_version, error, loading, expanded, setExpanded, isAllowed, version }}>
        <Helmet titleTemplate="UPshow - %s" defaultTitle="EverPass"/>
        <div id="grid" className={expanded ? 'expanded' : 'contracted'}>
            <Header/>
            <Menu/>
            <div id="main">
                {!user && error && <Error error={error}/>}
                {user && <Suspense fallback={<Loading/>}>
                    <Switch>
                        {/* Individual Sections */}
                        <Route path="/organization" component={Organizations}/>
                        <Route path="/users" component={Users}/>
                        <Route path="/devices" component={Devices}/>
                        <Route path="/plugins" component={Plugins}/>
                        {(isAllowed('ROLE_CS_TEAM') || isAllowed('ROLE_MEDIA_CHANNEL_MANAGER')) && <Route path="/media_channel" component={MediaChannels}/>}
                        <Route path="/premium_audio" component={PremiumAudio}/>
                        {(isAllowed('ROLE_CS_TEAM') || isAllowed('ROLE_MEDIA_CHANNEL_MANAGER')) && <Route path="/entertainment" component={EntertainmentSection}/>}
                        {isAllowed('ROLE_BACKEND_LIVE_EVENT') && <Route path="/live_events" component={LiveEvents}/>}
                        {isAllowed('ROLE_GRAPHQL') && <Route path="/graphql" component={GraphQL}/>}
                        {isAllowed('ROLE_BACKEND_ANALYTICS') && <Route path="/analytics" component={Analytics}/>}
                        <Route path="/upshownow" component={UPshowNow}/>
                        {isAllowed('ROLE_UI_VERSION_MASTER') && <Route path="/ui/loaders" component={UIVersioning}/>}
                        <Route path="/content" component={ContentSection}/>
                        <Route path="/uiscript" component={UIScript}/>
                        {isAllowed('ROLE_FULFILLMENT_ORDER') && <Route path="/fulfillment_order" component={FulfillmentOrder}/>}
                        {isAllowed('ROLE_SALE_ORDER') && <Route path="/sales_orders" component={SalesOrders}/>}
                        {isAllowed('ROLE_SMART_PAPER_REQUEST') && <Route path="/smart_paper" component={SmartPaper}/>}
                        {(isAllowed('ROLE_BOH_ALOHA') || isAllowed('ROLE_BOH_EPSILON') || isAllowed('ROLE_BOH_EXPLORER') || isAllowed('ROLE_ENGINEER')) && <Route path="/backofhouse" component={BackOfHouse}/>}
                        {(isAllowed('ROLE_MARKETING') || isAllowed('ROLE_ENGINEER')) && <Route path="/banners" component={Banners}/>}
                        {isAllowed('ROLE_SURVEYS') && <Route path="/surveys" component={Surveys}/>}
                        <Route path="/tracking_codes" component={TrackingCodes}/>

                        {/* Individual pages */}
                        <Route path="/content-:id" component={Content}/>
                        <Route path="/spotlight-:id" component={Spotlight}/>
                        <Route path="/screen-:id" component={Device}/>
                        <Route path="/note-:id" component={Note}/>
                        <Route path="/trackingcode-:id" component={TrackingCode}/>
                        <Route path="/notifications" component={Notifications}/>

                        {/* Errors & Defaults */}
                        <Route path="/" exact component={EmptySection}/>
                        <Route component={NotAllowedMessage} path="/403"/>
                        <Route component={NoMatchUrl} path="/404"/>
                        <Route component={Loading} path="/loading"/>
                        <Route component={ErrorPage} path="/error"/>
                        <Route component={NoMatchUrl}/>
                    </Switch>
                </Suspense>}
                <ToastContainer position="bottom-center" autoClose={2500} newestOnTop={false} closeOnClick pauseOnFocusLoss/>
            </div>
        </div>

        {/* Modals */}
        <Suspense fallback={<Loading/>}>
            <Route path="/*/spotlight-:id" component={Spotlight}/>
            <Route path="/*/screen-:id" component={Device}/>
            <Route path="/*/content-:id" component={Content}/>
            <Route path="/*/note-:id" component={Note}/>
            <Route path="/*/trackingcode-:id" component={TrackingCode}/>
        </Suspense>
        {isAllowed('ROLE_ENGINEER') && <Symfony/>}
    </AppContext.Provider>;
};

export default Home;
