import { useEffect, useState } from "react";
import { useQuery } from '@apollo/client';
import { GET_SPORT_LEAGUE_GENDERS } from '../Sections/LiveEvent/queries';

const LeaguePicker = ({ onChange, teamRelatedLeagues = [] }) => {
    const { data: sportLeagueGenders } = useQuery(GET_SPORT_LEAGUE_GENDERS, { fetchPolicy: 'cache-and-network' });
    const dataLeagueGenders = sportLeagueGenders?.backend.LeagueGenders ?? [];
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        setSelectedOptions(teamRelatedLeagues);
    }, [dataLeagueGenders])

    const handleOptionSelect = (option) => {
        const updatedSelectedOptions = [...selectedOptions];
        const optionIndex = selectedOptions.findIndex((o) => o.id === option.id);
        if (optionIndex !== -1) {
            updatedSelectedOptions.splice(optionIndex, 1);
        } else {
            updatedSelectedOptions.push(option);
        }
        setSelectedOptions(updatedSelectedOptions);
        onChange(updatedSelectedOptions);
    };

    return (
        <div style={{ overflowY: 'scroll', maxHeight: '900px', border: 'solid', padding: '10px', borderRadius: '5px', borderColor: '#dee2e6' }}>
            <div>
                {dataLeagueGenders && dataLeagueGenders.length &&
                    dataLeagueGenders.map((option) => (
                        <div key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                style={{ marginRight: '5px' }}
                                type="checkbox"
                                checked={selectedOptions.some((o) => o.id === option.id)}
                                onChange={() => handleOptionSelect(option)}
                            />
                            {option.display_name}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default LeaguePicker;